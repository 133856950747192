import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 612.000000 428.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,958.000000) scale(0.100000,-0.100000)">
          <path d="M6005 8484 c-644 -52 -1295 -299 -1815 -688 -615 -460 -1053 -1084
-1275 -1817 -97 -324 -135 -571 -142 -934 -9 -467 42 -798 186 -1220 300 -876
928 -1595 1752 -2006 378 -188 765 -303 1204 -355 158 -19 540 -22 710 -5 774
76 1495 399 2061 922 1003 928 1382 2360 967 3654 -343 1070 -1163 1906 -2226
2271 -212 72 -433 124 -682 160 -106 15 -620 27 -740 18z m665 -220 c822 -93
1583 -495 2137 -1129 386 -442 657 -1021 757 -1616 65 -387 60 -823 -15 -1194
-105 -521 -340 -1025 -670 -1436 -106 -133 -376 -402 -499 -500 -750 -594
-1670 -841 -2595 -698 -1137 176 -2114 939 -2552 1994 -174 420 -257 832 -256
1280 1 512 94 935 310 1407 101 222 225 424 397 648 104 135 420 451 556 556
550 425 1170 659 1870 708 102 7 424 -4 560 -20z"/>
<path d="M6075 8164 c-584 -44 -1150 -247 -1611 -577 -185 -133 -303 -235
-465 -402 -404 -417 -697 -965 -814 -1520 -9 -44 -20 -98 -25 -120 -47 -220
-61 -709 -27 -957 44 -315 118 -594 223 -838 308 -719 834 -1280 1524 -1626
334 -167 612 -251 1055 -316 139 -20 618 -17 770 6 402 59 668 139 1000 301
325 159 583 342 841 600 278 276 465 539 637 895 144 298 222 558 283 945 23
143 26 671 5 810 -24 165 -81 429 -115 540 -104 341 -284 697 -501 991 -152
206 -431 482 -642 637 -469 346 -914 524 -1533 617 -75 11 -510 21 -605 14z
m-95 -1429 c39 -20 60 -71 59 -147 -1 -92 -14 -168 -30 -179 -22 -14 -115 -11
-128 5 -8 9 -8 35 0 95 8 61 8 86 0 94 -21 21 -39 -15 -50 -100 -6 -50 -7 -92
-2 -106 5 -13 38 -49 74 -81 89 -78 93 -91 78 -242 -15 -153 -31 -207 -73
-251 -43 -45 -88 -63 -155 -63 -137 0 -177 85 -139 297 17 93 25 103 92 103
73 0 80 -14 60 -124 -20 -106 -20 -113 4 -126 18 -10 22 -7 35 24 8 19 17 77
21 130 l7 96 -64 62 c-82 81 -89 90 -99 135 -9 40 11 218 30 268 42 112 175
164 280 110z m1256 14 c87 -22 117 -97 94 -237 -18 -112 -19 -113 -93 -110
l-62 3 3 103 c3 89 2 102 -12 102 -21 0 -32 -28 -43 -118 -11 -87 0 -117 61
-165 86 -67 96 -85 96 -172 0 -102 -24 -244 -49 -293 -32 -63 -98 -102 -173
-102 -120 0 -163 44 -162 165 1 94 22 207 42 223 21 16 93 16 116 -1 17 -12
18 -20 7 -97 -13 -100 -14 -121 -1 -140 18 -29 41 13 56 105 22 122 16 141
-62 217 -36 34 -72 75 -80 90 -18 35 -18 111 1 216 23 130 67 189 160 211 54
12 51 12 101 0z m-2376 -244 c5 -216 12 -266 31 -205 4 14 33 113 65 220 32
107 62 201 67 208 7 9 35 12 96 10 l86 -3 0 -45 c-1 -25 -7 -83 -13 -130 -29
-194 -84 -582 -97 -674 -8 -57 -19 -103 -27 -107 -18 -12 -99 -11 -117 0 -13
8 -13 22 -2 98 14 95 24 221 14 188 -3 -11 -9 -25 -14 -30 -4 -6 -12 -29 -18
-50 -33 -138 -52 -199 -63 -206 -20 -13 -102 -11 -116 3 -8 8 -12 58 -12 160
0 87 -4 148 -10 148 -12 0 -30 -66 -30 -113 0 -57 -21 -180 -32 -194 -15 -18
-127 -17 -134 1 -5 14 5 107 31 276 8 52 28 196 45 320 29 218 48 337 57 353
3 4 46 6 96 5 l92 -3 5 -230z m744 220 c3 -8 1 -65 -5 -127 -10 -116 -15 -184
-45 -568 -19 -257 -20 -260 -95 -260 -52 0 -83 20 -75 49 3 9 8 66 12 127 l6
111 -25 7 c-52 13 -59 -2 -92 -179 -9 -49 -21 -95 -27 -102 -11 -13 -94 -18
-122 -7 -20 8 -20 46 -2 127 8 34 22 103 31 152 8 50 20 106 25 125 5 19 30
145 56 280 26 135 51 253 56 263 8 15 26 17 152 17 118 0 145 -3 150 -15z
m680 0 c3 -9 -1 -62 -9 -118 -9 -56 -20 -133 -25 -171 -11 -73 -6 -90 26 -84
16 3 23 25 41 133 12 72 22 148 23 170 0 66 21 85 90 85 32 0 61 -4 65 -10 6
-9 -7 -140 -25 -245 -6 -33 -24 -161 -40 -285 -17 -124 -34 -250 -40 -280 -5
-30 -12 -70 -15 -89 -8 -51 -21 -61 -80 -61 -76 0 -80 8 -61 132 21 141 36
255 36 278 0 26 -46 28 -54 3 -5 -20 -20 -123 -41 -281 -7 -56 -18 -109 -25
-117 -17 -21 -123 -21 -140 0 -7 8 -9 23 -6 32 4 10 14 69 22 133 30 228 77
568 95 690 7 41 16 81 22 88 15 19 133 16 141 -3z m611 5 c6 -10 -7 -239 -25
-425 -5 -55 -17 -192 -26 -305 -10 -113 -22 -211 -27 -218 -7 -9 -30 -12 -71
-10 l-61 3 0 60 c0 33 4 95 8 137 7 71 6 78 -12 88 -12 6 -32 8 -45 4 -20 -5
-26 -14 -31 -47 -19 -119 -46 -231 -57 -238 -21 -14 -114 -10 -127 4 -12 15
-8 42 31 232 11 55 26 136 34 179 8 43 19 100 25 125 6 25 17 80 25 121 24
128 42 215 54 259 l11 41 144 0 c87 0 146 -4 150 -10z m681 -5 c3 -8 -2 -70
-12 -137 -9 -68 -19 -149 -21 -180 -4 -53 -3 -58 16 -58 11 0 25 8 30 18 5 9
19 91 32 182 12 91 27 171 32 178 7 9 30 12 71 10 59 -3 61 -4 64 -32 3 -27
-12 -161 -38 -321 -9 -60 -21 -143 -61 -435 -11 -85 -25 -161 -31 -167 -12
-15 -106 -17 -127 -4 -13 8 -12 35 9 193 13 101 24 192 24 203 1 21 -30 33
-46 17 -5 -5 -22 -99 -37 -207 -19 -133 -33 -200 -42 -206 -25 -15 -129 -11
-135 6 -5 12 23 236 66 525 11 77 37 264 46 335 12 92 15 95 89 95 48 0 67 -4
71 -15z m630 0 c6 -14 -17 -359 -36 -560 -5 -55 -15 -161 -20 -235 -6 -74 -16
-140 -22 -147 -15 -18 -112 -17 -127 1 -9 11 -10 42 -2 128 14 149 15 145 -14
152 -50 13 -61 -4 -84 -131 -31 -167 -29 -163 -100 -163 -38 0 -63 5 -70 14
-10 11 -3 60 30 223 23 114 44 219 46 233 30 166 97 478 105 487 16 20 287 18
294 -2z m-2605 -1237 c75 -34 120 -76 154 -145 26 -51 30 -71 33 -168 2 -69
-3 -155 -13 -230 -9 -66 -20 -153 -25 -194 -5 -54 -13 -78 -26 -88 -13 -9 -62
-13 -181 -13 -146 0 -164 2 -170 17 -4 10 5 103 20 208 34 239 35 279 4 299
-55 37 -88 -8 -111 -154 -48 -297 -50 -326 -32 -373 28 -74 74 -125 198 -218
65 -50 132 -108 148 -130 56 -78 77 -192 60 -329 -12 -98 -34 -278 -50 -400
-33 -255 -147 -412 -347 -482 -96 -33 -271 -32 -356 1 -61 24 -143 95 -170
147 -39 78 -46 235 -18 429 48 333 53 360 78 369 31 12 310 5 329 -8 16 -12
16 -21 5 -107 -7 -52 -16 -116 -21 -144 -25 -142 -42 -288 -36 -318 8 -45 43
-65 84 -48 41 17 68 84 86 211 51 358 51 371 13 440 -34 62 -64 95 -154 170
-159 134 -200 187 -221 285 -20 92 -22 120 -12 200 5 44 14 125 20 180 24 220
53 323 121 425 48 72 93 111 175 151 78 39 135 49 259 45 83 -2 111 -7 156
-28z m2030 -2 c153 -33 234 -112 266 -259 13 -61 13 -93 3 -207 -6 -74 -16
-164 -21 -199 -14 -89 -61 -206 -109 -269 -22 -29 -40 -57 -40 -63 0 -6 14
-27 31 -47 55 -62 63 -93 61 -237 0 -71 -5 -161 -11 -200 -5 -38 -21 -155 -35
-260 -14 -104 -32 -218 -41 -253 -46 -192 -170 -324 -357 -382 -55 -17 -590
-23 -612 -7 -15 11 -15 22 -7 92 8 75 48 360 71 520 12 80 45 321 61 440 5 44
14 107 19 140 5 33 19 130 30 215 12 85 27 198 35 250 8 52 28 200 45 328 33
247 54 369 65 380 29 29 431 42 546 18z m-1237 -46 c0 -25 -8 -99 -17 -165 -9
-66 -24 -169 -32 -230 -8 -60 -19 -139 -24 -175 -18 -121 -50 -355 -56 -405
-4 -27 -13 -90 -20 -140 -8 -49 -26 -175 -40 -280 -14 -104 -32 -233 -40 -285
-42 -284 -42 -324 -4 -348 17 -11 27 -11 49 -2 48 20 59 57 90 290 5 41 17
120 25 175 17 111 28 188 50 350 8 61 22 157 30 215 49 335 68 471 85 605 11
83 22 161 26 175 3 14 12 73 19 133 7 59 19 114 25 122 19 22 348 22 356 0 7
-17 -12 -176 -56 -490 -42 -293 -52 -369 -60 -430 -4 -38 -31 -227 -58 -420
-28 -192 -60 -419 -72 -504 -37 -267 -99 -393 -241 -487 -142 -94 -343 -110
-493 -40 -138 64 -200 214 -176 425 6 53 15 125 21 161 13 87 83 587 109 780
11 85 27 198 35 250 8 52 26 178 40 280 14 102 32 228 39 280 8 52 17 115 21
140 10 72 7 71 199 68 l171 -3 -1 -45z"/>
<path d="M5428 6597 c-9 -7 -20 -41 -27 -82 -6 -38 -19 -119 -30 -179 -20
-111 -18 -136 14 -136 28 0 38 35 51 178 7 81 15 161 18 177 7 40 -5 59 -26
42z"/>
<path d="M6696 6508 c-9 -51 -21 -113 -27 -138 -5 -25 -11 -72 -12 -105 -2
-55 0 -60 20 -63 32 -5 40 21 53 168 6 69 13 149 16 178 6 47 5 52 -14 52 -17
0 -22 -12 -36 -92z"/>
<path d="M8014 6553 c-3 -27 -17 -104 -30 -171 -13 -68 -24 -135 -24 -148 0
-29 21 -40 47 -25 17 8 22 33 36 188 21 218 21 203 -2 203 -16 0 -21 -10 -27
-47z"/>
<path d="M7420 5156 c-6 -8 -25 -111 -41 -228 -17 -117 -34 -238 -38 -268 -10
-65 -6 -80 21 -80 37 0 85 61 107 136 22 73 51 260 51 329 0 60 -18 105 -47
116 -32 12 -38 12 -53 -5z"/>
<path d="M7293 4242 c-11 -7 -35 -149 -68 -406 -8 -61 -22 -163 -30 -226 -25
-185 -31 -165 49 -168 112 -5 111 -7 161 338 49 330 46 428 -14 460 -23 12
-81 13 -98 2z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
